<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-sm-12.col-md-6
    +data-info('number', 'statement.number || statement.other_number')
  div.col-sm-12.col-md-6
    +data-info('dateIssue', 'getDateFormat(statement.date_start)')
  div.col-sm-12.col-md-6
    +data-info('passportIssued', 'getDirectoryObject({ value: "issuingAuthority", id: statement.issuing_authority })[nameLang]')
  div(v-if="statement.strict_blank").col-sm-12.col-md-6
    +data-info('strictBlank', 'statement.strict_blank')
  div(v-if="this.statement.authorized_official_ukr").col-sm-12.col-md-6
    +data-info('agent', 'agent')
  div.col-sm-12.col-md-6
    +data-info('typeDoc', 'getDirectoryObjectByKey({ value: statement.type, state: "navigatorCertificateTypes", key: "value" })[nameLang]')
  div(v-if="statement.type === 'ukrainian' && statement.position").col-sm-12.col-md-6
    +data-info('position', 'getDirectoryObject({ value: "positions", id: statement.position })[nameLang]')
  div(v-if="statement.type === 'ukrainian'").col-sm-12.col-md-6
    +data-info('shipGroup', 'getDirectoryObject({ value: "shipGroups", id: statement.ship_group })[nameLang]')
  div(v-if="statement.type === 'european' && statement.category").col-sm-12.col-md-6
    +data-info('category', 'statement.category')
  div(v-if="statement.type === 'european' && statement.radio_equipment?.length").col-sm-12.col-md-6
    b {{ $t('shipEquipment') }}:
    span(v-for="(equipment, index) in statement.radio_equipment" :key="index").mr-2 {{ getDirectoryObject({ value: 'radioEquipment', id: equipment })[nameLang] }} {{ statement.radio_equipment.length - 1 !== index ? ';' : '' }}
  div(v-if="statement.type === 'european'").col-sm-12.col-md-6
    +data-info('shipEquipment', 'getDateFormat(statement.date_end)')
  div(v-if="statement.type === 'european' && statement.rivers?.length").col-sm-12.col-md-6
    b {{ $t('river') }}:
    span(v-for="(river, index) in statement.rivers" :key="index").mr-2 {{ getDirectoryObject({ value: 'rivers', id: river.river })[nameLang] }} {{ river.start_area }}-{{ river.end_area }} {{ statement.radio_equipment.length - 1 !== index ? ';' : '' }}
  div(v-if="checkAccess('document-author-view')").col-sm-12.col-md-6
    +data-info('createDate', 'statement.created_at')
  div.col-sm-12.col-md-6
    +data-info-status('status', 'getDirectoryObject({ value: "statuses", id: statement.status_document })[nameLang]', 'getStatus(statement.status_document)')
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SailorCertificateOfNavigationInfo',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      getDateFormat
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject', 'getDirectoryObjectByKey']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    agent () {
      return `${this.statement.authorized_official_ukr} (${this.statement.authorized_official_eng})`
    }
  }
}
</script>
